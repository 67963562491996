import React from "react";
import Banner from "../components/Banner/Banner";
import CoinsTable from "../components/CoinsTable";
// import PresaleModal from "../components/PresaleModal"; // Import the PresaleModal component
import Footer from "../components/Footer";
import MarketingSection from "../components/MarketingSection"; // Import the MarketingSection component
import WalletModal from "../components/WalletModal";

const Homepage = () => {
  return (
    <>
      <WalletModal />
      {/* Add the MarketingSection component */}
      <Banner />
      <CoinsTable />
      <MarketingSection /> {/* Add the MarketingSection component */}
      <Footer />
    </>
  );
};

export default Homepage;
