import React, { useMemo, useState } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { CryptoState } from "../CryptoContext";
import AuthModal from "./Authentication/AuthModal";
import UserSideBar from "./Authentication/UserSideBar";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { clusterApiUrl } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css"; // Import the wallet adapter styles
import newLogo from "../assets/newLogo.png"; // Import the new logo
import useWindowSize from "../utils/useWindowSize";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hamburgerMenu: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 250,
  },
  drawerPaper: {
    width: 250,
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    mode: "dark",
  },
});

function Header() {
  const classes = useStyles();
  const { user, setOpen } = CryptoState();
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Configure the network and wallets
  const network = clusterApiUrl("mainnet-beta");
  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    []
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleJoinWhitelist = () => {
    setOpen(true); // Adjust the path to your whitelist page
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <ConnectionProvider endpoint={network}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <AppBar color="transparent" position="static">
              <Container>
                <Toolbar
                  style={{
                    padding: 0,
                  }}
                >
                  <div
                    onClick={() => history.push(`/`)}
                    className={classes.title}
                  >
                    <img
                      src={newLogo}
                      alt="Retro Llama"
                      style={{
                        width: isMobile ? 50 : 50,
                        marginRight: 10,
                        padding: 2,
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className={classes.buttonGroup}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleJoinWhitelist}
                      sx={{
                        backgroundColor: "#ffeb3a",
                        color: "#E6007A",
                        marginRight: 2,
                        fontFamily: "Montserrat",
                        fontSize: ".875rem",
                        fontWeight: "600",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#E6007A",
                          color: "#fff",
                        },
                      }}
                    >
                      Join Presale
                    </Button>
                    <WalletMultiButton
                      style={{
                        backgroundColor: "#E6007A",
                        color: "white",
                        borderRadius: 3,
                        padding: 10,
                        marginRight: 15,
                        fontFamily: "Montserrat",
                        fontSize: ".875rem",
                        height: 42,
                      }}
                    />
                    {user ? <UserSideBar /> : <AuthModal />}
                  </div>

                  {isMobile && (
                    <>
                      <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        className={classes.hamburgerMenu}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        classes={{ paper: classes.drawerPaper }}
                      >
                        <List className={classes.drawer}>
                          <ListItem
                            button
                            onClick={handleJoinWhitelist}
                            sx={{
                              backgroundColor: "#ffeb3a",
                              color: "#333",
                              fontFamily: "Montserrat",
                              fontSize: ".875rem",
                              fontWeight: "600",
                              textTransform: "none",
                              borderRadius: 1,
                              "&:hover": {
                                backgroundColor: "#E6007A",
                                color: "#fff",
                              },
                              margin: "10px",
                              textAlign: "center",
                              width: "auto",
                            }}
                          >
                            <ListItemText
                              primary="Join Whitelist"
                              sx={{
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            />
                          </ListItem>
                          <ListItem>
                            <WalletMultiButton
                              style={{
                                backgroundColor: "#E6007A",
                                color: "white",
                                borderRadius: 3,
                                padding: 10,
                                fontFamily: "Montserrat",
                                fontSize: ".875rem",
                                height: 42,
                              }}
                            />
                          </ListItem>
                          <ListItem>
                            {user ? <UserSideBar /> : <AuthModal />}
                          </ListItem>
                        </List>
                      </Drawer>
                    </>
                  )}
                </Toolbar>
              </Container>
            </AppBar>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
}

export default Header;
