import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  Grid,
  Tooltip,
  Container,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CryptoState } from "../CryptoContext";
import LlamaImage from "../assets/Logo.webp";
import useWindowSize from "../utils/useWindowSize";
import { MdContentCopy } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "500px",
    margin: "auto",
  },
  paper: {
    backgroundColor: "#E6007A",
    border: "2px solid #ffeb3b",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 4),
    maxWidth: 400,
    textAlign: "center",
    color: "#ffffff",
    position: "relative",
  },
  title: {
    backgroundColor: "#ffeb3b",
    width: "auto",
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    color: "#E6007A",
  },
  closeButton: {
    marginTop: "20px",
    backgroundColor: "#ffeb3b",
    color: "#E6007A",
    "&:hover": {
      backgroundColor: "#e6c933",
    },
  },
  llamaImage: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    position: "absolute",
    top: -65,
    left: "calc(50% - 50px)",
    border: "3px solid #ffeb3b",
  },
  contractAddress: {
    marginTop: theme.spacing(2),
  },
  dateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffeb3b",
    color: "#333",
    padding: theme.spacing(1),
    borderRadius: 5,
    marginTop: theme.spacing(2),
    flexDirection: "column",
  },
  dateText: {
    fontWeight: "bolder",
    fontSize: "1.4rem",
  },
  calendarButton: {},
  emphasizedText: {
    fontWeight: 900,
    fontSize: "1.8rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
}));

const WalletModal = () => {
  const { isMobile } = useWindowSize();
  const classes = useStyles();
  const { open, setOpen } = CryptoState();
  const contractAddress = "FYHr6YAACyTPuALnCTcgi8Pf1odXS21UzLBX1vNskSUQ";

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="wallet-modal-title"
      aria-describedby="wallet-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Container className={classes.paper}>
          <img
            src={LlamaImage}
            alt="Retro Llama"
            className={classes.llamaImage}
          />
          <Typography
            variant="h5"
            id="wallet-modal-title"
            className={classes.title}
          >
            Presale is Live!
            <Button
              variant="outlined"
              className={classes.closeButton}
              onClick={handleClose}
              size="small"
              sx={{
                backgroundColor: "#e6c933",
                color: "#E6007A",
                position: "absolute",
                width: 30,
                height: 30,
                top: 10,
                right: 10,
                "&:hover": {
                  backgroundColor: "#e6c933",
                },
              }}
            >
              X
            </Button>
          </Typography>

          <Typography
            variant="subtitle2"
            id="wallet-modal-description"
            paragraph
            className={classes.emphasizedText}
          >
            Send 1 SOL to the following wallet address to receive 1 million
            LLAMA tokens.
          </Typography>
          <Grid item xs={12} sm={4} textAlign="center">
            <div className={classes.contractAddress}>
              <Tooltip title={contractAddress} arrow>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ffeb3b",
                    color: "#E6007A",
                    "&:hover": {
                      backgroundColor: "#e6c933",
                    },
                    marginLeft: isMobile ? 0 : 0,
                    marginTop: isMobile ? 0 : 0,
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                  onClick={handleCopy}
                >
                  Official Llama Presale Wallet <MdContentCopy />
                </Button>
              </Tooltip>
            </div>
          </Grid>

          <Typography
            variant="body1"
            id="wallet-modal-description"
            paragraph
            className={classes.emphasizedText}
          >
            Presale ends on: <br />
            October 7th 23:59 UTC
          </Typography>
          <Typography
            variant="subtitle2"
            id="wallet-modal-description"
            paragraph
            className={classes.emphasizedText}
          >
            300 SOL hardcap for the presale. 10 SOl minimum to launch or we will
            have to extend the launch.
          </Typography>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert onClose={handleSnackbarClose} severity="success">
              Wallet address copied to clipboard!
            </MuiAlert>
          </Snackbar>
        </Container>
      </Fade>
    </Modal>
  );
};

export default WalletModal;
