import { useState, useEffect } from "react";

// Define breakpoints for different devices
const MOBILE_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1024;

function useWindowSize() {
  // State to store the current window dimensions
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to handle window resize
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine device type based on window width
  const isMobile = windowSize.width <= MOBILE_BREAKPOINT;
  const isTablet =
    windowSize.width > MOBILE_BREAKPOINT &&
    windowSize.width <= TABLET_BREAKPOINT;
  const isDesktop = windowSize.width > TABLET_BREAKPOINT;

  return { windowSize, isMobile, isTablet, isDesktop };
}

export default useWindowSize;
