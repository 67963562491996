import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Homepage from "./Pages/HomePage";
import CoinPage from "./Pages/CoinPage";
import Header from "./components/Header";
import Alert from "./components/Alert";
import WhitePaper from "./Pages/WhitePaper";
import WalletContextProvider from "./WalletContextProvider";
import "./App.css";

const useStyles = makeStyles(() => ({
  App: {
    backgroundColor: "#06070D",
    color: "#FFF",
    minHeight: "100vh",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#E6007A",
    },
    background: {
      paper: "#333",
    },
  },
  shadows: [
    "none",
    ...Array(24).fill(
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
    ),
  ],
});

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <WalletContextProvider>
          <div className={classes.App}>
            <Header />
            <Route path="/" component={Homepage} exact />
            <Route path="/whitepaper" component={WhitePaper} exact />
            <Route path="/coins/:id" component={CoinPage} exact />
          </div>
          <Alert />
        </WalletContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
