import React, { useState } from "react";
import {
  Container,
  Button,
  Snackbar,
  Grid,
  Tooltip,
  Alert as MuiAlert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MdContentCopy } from "react-icons/md";
import Logo from "../assets/favicon.webp";
import useWindowSize from "../utils/useWindowSize";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#FFF9EA",
    color: "#000000",
    textAlign: "center",
    padding: "20px 0",
    borderTop: "1px solid #ddd",
  },
  logo: {
    width: 150,
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
    "& a": {
      color: "#E6007A",
      margin: "0 15px",
      fontSize: "1.5rem",
      textDecoration: "none",
      transition: "color 0.3s",
      "&:hover": {
        color: "#d4006b",
      },
    },
  },
  contractAddress: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#000000",
    textAlign: "center",
    marginTop: "20px",
  },
  button: {
    marginTop: "10px",
    backgroundColor: "#E6007A",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#d4006b",
    },
  },
}));

const Footer = () => {
  const { isMobile } = useWindowSize();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const contractAddress = "5ybhspwr2nPH4zTXq95QdE4CuoxmasDuJ1d1h74hmehb";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={4} className={classes.socialIcons}>
            <a href="https://t.me/RetroLlama" aria-label="Telegram">
              <FaTelegramPlane size={isMobile ? 24 : 32} />
            </a>
            <a href="https://x.com/RetroLLama1984" aria-label="Twitter">
              <FaTwitter size={isMobile ? 24 : 32} />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <img src={Logo} alt="Retro Llama" className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <div className={classes.contractAddress}>
              <Tooltip title={contractAddress} arrow>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#E6007A",
                    color: "#FFF",
                    "&:hover": {
                      backgroundColor: "#d4006b",
                    },
                    marginLeft: isMobile ? 0 : 0,
                    marginTop: isMobile ? 0 : 0,
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                  onClick={handleCopy}
                >
                  Official Contract <MdContentCopy />
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success">
          Contract address copied to clipboard!
        </MuiAlert>
      </Snackbar>
    </footer>
  );
};

export default Footer;
