import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { CoinList } from "../src/config/api";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";

const Crypto = createContext();

const CryptoContext = ({ children }) => {
  const [currency, setCurrency] = useState("USD");
  const [symbol, setSymbol] = useState("$");
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [watchlist, setWatchlist] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchCoins = useCallback(async () => {
    const cacheKey = `coins_${currency}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cacheExpiry = 5 * 60 * 1000; // 5 minutes

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      if (Date.now() - parsedData.timestamp < cacheExpiry) {
        setCoins(parsedData.data);
        setLoading(false);
        return;
      }
    }

    setLoading(true);
    try {
      const { data } = await axios.get(CoinList(currency));
      setCoins(data);
      localStorage.setItem(
        cacheKey,
        JSON.stringify({ timestamp: Date.now(), data })
      );
    } catch (error) {
      console.error("Failed to fetch coins:", error);
    }
    setLoading(false);
  }, [currency]);

  const connectWallet = async () => {
    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        const response = await provider.connect();
        setWalletAddress(response.publicKey.toString());
      }
    } catch (err) {
      console.error("Error connecting to Phantom wallet:", err);
    }
  };

  const disconnectWallet = async () => {
    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        await provider.disconnect();
        setWalletAddress(null);
      }
    } catch (err) {
      console.error("Error disconnecting from Phantom wallet:", err);
    }
  };

  useEffect(() => {
    if (user) {
      const coinRef = doc(db, "watchlist", user.uid);
      const unsubscribe = onSnapshot(coinRef, (coin) => {
        if (coin.exists()) {
          setWatchlist(coin.data().coins);
        } else {
          console.log("No Items in Watchlist");
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) setUser(user);
      else setUser(null);
    });
  }, []);

  useEffect(() => {
    if (currency === "USD") setSymbol("$");
    else if (currency === "EUR") setSymbol("€");
    else if (currency === "GBP") setSymbol("£");
    else if (currency === "JPY") setSymbol("¥");
    else if (currency === "INR") setSymbol("₹");
    else if (currency === "BTC") setSymbol("₿");
    else if (currency === "ETH") setSymbol("Ξ");

    const debouncedFetchCoins = debounce(fetchCoins, 1000);
    debouncedFetchCoins();
  }, [currency, fetchCoins]);

  useEffect(() => {
    const provider = window.solana;
    if (provider && provider.isPhantom) {
      provider.on("connect", () => {
        setWalletAddress(provider.publicKey.toString());
      });
      provider.on("disconnect", () => {
        setWalletAddress(null);
      });
    }

    return () => {
      if (provider && provider.isPhantom) {
        provider.off("connect");
        provider.off("disconnect");
      }
    };
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  return (
    <Crypto.Provider
      value={{
        currency,
        setCurrency,
        symbol,
        coins,
        loading,
        alert,
        setAlert,
        user,
        setUser,
        watchlist,
        setWatchlist,
        walletAddress,
        setWalletAddress,
        connectWallet,
        disconnectWallet,
        open,
        setOpen,
      }}
    >
      {children}
    </Crypto.Provider>
  );
};

export default CryptoContext;

export const CryptoState = () => {
  return useContext(Crypto);
};
