// src/components/MarketingSection.js
import React from "react";
import { makeStyles } from "@mui/styles";
import { CryptoState } from "../CryptoContext";
import moonImage from "../assets/moon.webp";

const useStyles = makeStyles((theme) => ({
  marketingSection: {
    height: "30vh",
    background: `url(${moonImage}) no-repeat center center`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textAlign: "center",
  },
  content: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "20px",
    borderRadius: "10px",
    cursor: "pointer", // Add cursor pointer to indicate clickability
  },
}));

const MarketingSection = () => {
  const classes = useStyles();
  const { setOpen } = CryptoState();

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <div className={classes.marketingSection} onClick={handleOpenModal}>
      <div className={classes.content}>
        <h1>Join our Exclusive Presale!</h1>
        <p>
          Don't miss out on this opportunity to invest in Retro Llama Tokens at
          a discounted rate.
        </p>
      </div>
    </div>
  );
};

export default MarketingSection;
