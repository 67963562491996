import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Carousel from "./Carousel";
import { Link } from "react-router-dom";

import useWindowSize from "../../utils/useWindowSize";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: "url(./banner.webp)",
    backgroundPosition: "center",
    // backgroundSize: "cover",
    height: "100%",
    color: "#ffffff",
  },
  bannerMobile: {
    backgroundImage: "url(./mobileBanner.png)",
    backgroundPosition: "center",
    backgroundSize: "130%",
    height: "100%",
    color: "#ffffff",
  },
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    background: "rgba(0, 0, 0, 0.7)",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    // background: "rgba(0, 0, 0, 0.7)",
    padding: "20px",
    borderRadius: "10px",
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
  // socialIcons: {
  //   display: "flex",
  //   justifyContent: "space-evenly",
  //   marginTop: 10,
  // },
}));

function Banner() {
  const classes = useStyles();
  const { isMobile } = useWindowSize();

  return (
    <div className={isMobile ? classes.bannerMobile : classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography
            variant="h3"
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Montserrat",
              color: "#ffeb3b",
            }}
          >
            Retro Llama
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "lightgrey",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            Ride the Retro Wave with Real-Time Crypto Updates!
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "lightgrey",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              marginTop: 10,
            }}
          >
            <a
              href="https://solscan.io/address/5ybhspwr2nPH4zTXq95QdE4CuoxmasDuJ1d1h74hmehb"
              style={{ color: "#ffeb3b", marginLeft: 10 }}
            >
              Contract Address
            </a>{" "}
            |
            <Link to="/whitepaper" style={{ color: "#ffeb3b", marginLeft: 10 }}>
              White Paper
            </Link>
          </Typography>
        </div>
        <Carousel />
      </Container>
    </div>
  );
}

export default Banner;
