import React, { useState } from "react";
import {
  Modal,
  Fade,
  AppBar,
  Button,
  Tab,
  Tabs,
  Box,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import GoogleButton from "react-google-button";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { CryptoState } from "../../CryptoContext";
import Login from "./Login";
import SignUp from "./SignUp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
  },
  paper: {
    width: 400,
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: 10,
    padding: theme.spacing(2),
    boxShadow: "0 0 20px rgba(0,0,0,0.5)",
    position: "relative",
  },
  google: {
    padding: 24,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    gap: 20,
    fontSize: 20,
  },
  tab: {
    color: "#000",
    fontSize: 16,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#444",
    alignContent: "center",
  },
  appBar: {
    backgroundColor: "#444",
  },
  button: {
    backgroundColor: "#EEBC1D",
    color: "#333",
    "&:hover": {
      backgroundColor: "#d4a017",
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "#fff",
  },
}));

export default function AuthModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { setAlert } = CryptoState();

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        setAlert({
          open: true,
          message: `Sign Up Successful. Welcome ${res.user.email}`,
          type: "success",
        });
        handleClose();
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: error.message,
          type: "error",
        });
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        size="large"
        style={
          {
            // width: 118,
            // height: 45,
          }
        }
        onClick={handleOpen}
      >
        Login
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="auth tabs"
                variant="fullWidth"
                className={classes.tabs}
                TabIndicatorProps={{ style: { backgroundColor: "#EEBC1D" } }}
              >
                <Tab label="Login" className={classes.tab} />
                <Tab label="Sign Up" className={classes.tab} />
                <IconButton
                  sx={{ top: 0, right: 0, position: "absolute" }}
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tabs>
            </AppBar>
            {value === 0 && <Login handleClose={handleClose} />}
            {value === 1 && <SignUp handleClose={handleClose} />}
            <Box className={classes.google}>
              <span>OR</span>
              <GoogleButton
                style={{ width: "100%", outline: "none" }}
                onClick={signInWithGoogle}
              />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
