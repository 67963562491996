const PRIMARY_API_KEY = process.env.REACT_APP_APIKEY;
const SECONDARY_API_KEY = process.env.REACT_APP_SECONDARY_API_KEY;

const appendApiKey = (url, apiKey) => {
  if (apiKey === SECONDARY_API_KEY) {
    return `${url}&x_cg_demo_api_key=${apiKey}`;
  }
  return url;
};

export const CoinList = (currency, apiKey = PRIMARY_API_KEY) =>
  appendApiKey(
    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&order=market_cap_desc&per_page=100&page=1&sparkline=false`,
    apiKey
  );

export const SingleCoin = (id, apiKey = PRIMARY_API_KEY) =>
  appendApiKey(`https://api.coingecko.com/api/v3/coins/${id}`, apiKey);

export const HistoricalChart = (
  id,
  days = 365,
  currency,
  apiKey = PRIMARY_API_KEY
) =>
  appendApiKey(
    `https://api.coingecko.com/api/v3/coins/${id}/market_chart?vs_currency=${currency}&days=${days}`,
    apiKey
  );

export const TrendingCoins = (currency, apiKey = PRIMARY_API_KEY) =>
  appendApiKey(
    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&order=gecko_desc&per_page=10&page=1&sparkline=false&price_change_percentage=24h`,
    apiKey
  );

export const SearchCoin = (query, apiKey = PRIMARY_API_KEY) =>
  appendApiKey(
    `https://api.coingecko.com/api/v3/search?query=${query}`,
    apiKey
  );
