import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  link: {
    color: "#ffeb3b",
    textDecoration: "none",
  },
}));

const Whitepaper = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Retro Llama Token (LLAMA) Whitepaper
      </Typography>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the world of Retro Llama Token (LLAMA), a fun and nostalgic
          memecoin inspired by the vibrant 80s era. Our mission is to create a
          community-driven cryptocurrency that brings a sense of fun and
          engagement to the crypto space while providing real value and
          opportunities for its holders.
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Vision
        </Typography>
        <Typography variant="body1" paragraph>
          Retro Llama Token aims to become the go-to memecoin for enthusiasts
          who appreciate the nostalgic charm of the 80s combined with the
          innovative potential of blockchain technology. We envision a thriving
          community where members can share, trade, and benefit from holding
          LLAMA.
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Tokenomics ( Subject to Change )
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Total Supply:</strong> 1,000,000,000 LLAMA
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Initial Distribution:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          - Liquidity Pool: 30%
        </Typography>
        <Typography variant="body1" paragraph>
          - Community Rewards & Airdrops: 20%
        </Typography>
        <Typography variant="body1" paragraph>
          - Team & Development: 10%
        </Typography>
        <Typography variant="body1" paragraph>
          - Marketing & Partnerships: 10%
        </Typography>
        <Typography variant="body1" paragraph>
          - Reserve Fund: 30%
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Token Details:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          - Token Name: Retro Llama Token
        </Typography>
        <Typography variant="body1" paragraph>
          - Ticker: LLAMA
        </Typography>
        <Typography variant="body1" paragraph>
          - Blockchain: Solana
        </Typography>
        <Typography variant="body1" paragraph>
          - Contract Address: 5ybhspwr2nPH4zTXq95QdE4CuoxmasDuJ1d1h74hmehb
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Features
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Community-Driven:</strong> Retro Llama Token is all about the
          community. Our holders have a voice in the direction of the project
          through regular polls and discussions on our Telegram and Discord
          channels.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Rewards and Airdrops:</strong> To foster community growth and
          engagement, we offer generous rewards and airdrops. The first 10 users
          to join our Telegram and share their Solana wallet address receive
          10,000 LLAMA each. Additionally, we will have ongoing events to reward
          active community members.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Fun and Nostalgia:</strong> LLAMA brings the fun and nostalgia
          of the 80s to the crypto world. Our vibrant mascot, Retro Llama, and
          themed events make holding and using LLAMA an enjoyable experience.
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Roadmap
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Q4 2024:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          - Launch Retro Llama Token
        </Typography>
        <Typography variant="body1" paragraph>
          - Initial Airdrop Campaign
        </Typography>
        <Typography variant="body1" paragraph>
          - Create Liquidity Pool on Solana
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Q1 2025:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          - Marketing Campaign
        </Typography>
        <Typography variant="body1" paragraph>
          - Partnership Announcements
        </Typography>
        <Typography variant="body1" paragraph>
          - Community Voting for Future Developments
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Q2 2025:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          - Expand Rewards Program
        </Typography>
        <Typography variant="body1" paragraph>
          - Launch Retro-Themed NFT Collection
        </Typography>
        <Typography variant="body1" paragraph>
          - Integration with Additional Exchanges
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Conclusion
        </Typography>
        <Typography variant="body1" paragraph>
          Retro Llama Token is more than just a memecoin; it's a
          community-centric project that combines the playful spirit of the 80s
          with the revolutionary technology of blockchain. Join us on this
          exciting journey and be part of a vibrant community that is shaping
          the future of fun in crypto.
        </Typography>
      </div>
    </Container>
  );
};

export default Whitepaper;
