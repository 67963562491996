import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState, useMemo, useCallback } from "react";
import AliceCarousel from "react-alice-carousel";
import { Link } from "react-router-dom";
import { TrendingCoins } from "../../config/api";
import { CryptoState } from "../../CryptoContext";
import { numberWithCommas } from "../CoinsTable";
import useWindowSize from "../../utils/useWindowSize";

const Carousel = () => {
  const [trending, setTrending] = useState([]);
  const { currency, symbol } = CryptoState();
  const { isMobile } = useWindowSize();

  const fetchTrendingCoins = useCallback(
    async (forceUpdate = false) => {
      const cacheKey = `trendingCoins_${currency}`;
      const cachedData = localStorage.getItem(cacheKey);
      const cacheExpiry = 5 * 60 * 1000; // 5 minutes

      if (!forceUpdate && cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (Date.now() - parsedData.timestamp < cacheExpiry) {
          setTrending(parsedData.data);
          return;
        }
      }

      const { data } = await axios.get(TrendingCoins(currency));
      setTrending(data);
      localStorage.setItem(
        cacheKey,
        JSON.stringify({ timestamp: Date.now(), data })
      );
    },
    [currency]
  );

  useEffect(() => {
    const debouncedFetch = debounce(() => fetchTrendingCoins(), 1000);
    debouncedFetch();
  }, [currency, fetchTrendingCoins]);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const useStyles = makeStyles((theme) => ({
    carousel: {
      height: "50%",
      display: "flex",
      alignItems: "center",
    },
    carouselItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      textTransform: "uppercase",
      color: "white",
      marginTop: isMobile ? "5rem" : 0,
      scale: isMobile ? "50%" : "75%",
    },
  }));

  const classes = useStyles();

  const items = useMemo(
    () =>
      trending.map((coin) => {
        let profit = coin?.price_change_percentage_24h >= 0;

        return (
          <Link
            className={classes.carouselItem}
            to={`/coins/${coin.id}`}
            key={coin.id}
          >
            <img
              src={coin?.image}
              alt={coin.name}
              height="80"
              style={{ marginBottom: 10 }}
            />
            <span>
              {coin?.symbol}
              &nbsp;
              <span
                style={{
                  color: profit > 0 ? "rgb(14, 203, 129)" : "red",
                  fontWeight: 500,
                }}
              >
                {profit && "+"}
                {coin?.price_change_percentage_24h?.toFixed(2)}%
              </span>
            </span>
            <span style={{ fontSize: 22, fontWeight: 500 }}>
              {symbol} {numberWithCommas(coin?.current_price.toFixed(2))}
            </span>
          </Link>
        );
      }),
    [trending, classes.carouselItem, symbol]
  );

  const responsive = {
    0: {
      items: 2,
    },
    512: {
      items: 4,
    },
  };

  return (
    <div className={classes.carousel}>
      <AliceCarousel
        mouseTracking
        infinite
        autoPlayInterval={1000}
        animationDuration={1500}
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        items={items}
        autoPlay
      />
    </div>
  );
};

export default Carousel;
