import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import {
  Container,
  createTheme,
  ThemeProvider,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";

import { useHistory } from "react-router-dom";
import { CryptoState } from "../CryptoContext";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Import dark theme CSS
import axios from "axios";
import { CoinList, SearchCoin, SingleCoin } from "../config/api";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const useStyles = makeStyles({
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "#FFF",
    },
  },
});

export default function CoinsTable() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [totalCoins, setTotalCoins] = useState(0);
  const PRIMARY_API_KEY = process.env.REACT_APP_APIKEY;
  const SECONDARY_API_KEY = process.env.REACT_APP_SECONDARY_API_KEY;

  const { symbol, currency, setCurrency } = CryptoState();
  const classes = useStyles();
  const history = useHistory();

  const fetchCoins = useCallback(
    async (apiKey = PRIMARY_API_KEY) => {
      try {
        const { data } = await axios.get(CoinList(currency, apiKey));
        setRowData(data);
        setTotalCoins(data.length);
      } catch (error) {
        console.error("Failed to fetch coins with primary key:", error);
        if (apiKey === PRIMARY_API_KEY) {
          console.log("Retrying with secondary API key...");
          fetchCoins(SECONDARY_API_KEY);
        }
      }
    },
    [currency, PRIMARY_API_KEY, SECONDARY_API_KEY]
  );

  const fetchCoinDetails = useCallback(
    async (id, apiKey = PRIMARY_API_KEY) => {
      try {
        const { data } = await axios.get(SingleCoin(id, apiKey));
        return data;
      } catch (error) {
        console.error("Failed to fetch coin details with primary key:", error);
        if (apiKey === PRIMARY_API_KEY) {
          console.log("Retrying with secondary API key...");
          return fetchCoinDetails(id, SECONDARY_API_KEY);
        }
        return null;
      }
    },
    [PRIMARY_API_KEY, SECONDARY_API_KEY]
  );

  const handleSearch = useCallback(
    async (apiKey = PRIMARY_API_KEY) => {
      if (!search) {
        fetchCoins(apiKey);
        return;
      }

      try {
        const { data } = await axios.get(SearchCoin(search, apiKey));
        const coinIds = data.coins.map((coin) => coin.id);
        const coinDetailsPromises = coinIds.map((id) =>
          fetchCoinDetails(id, apiKey)
        );
        const coinsDetails = await Promise.all(coinDetailsPromises);

        setRowData(coinsDetails.filter((coin) => coin !== null));
        setTotalCoins(coinsDetails.length);
      } catch (error) {
        console.error("Failed to search coins with primary key:", error);
        if (apiKey === PRIMARY_API_KEY) {
          console.log("Retrying with secondary API key...");
          handleSearch(SECONDARY_API_KEY);
        }
      }
    },
    [search, PRIMARY_API_KEY, SECONDARY_API_KEY, fetchCoins, fetchCoinDetails]
  );

  useEffect(() => {
    handleSearch();
  }, [search, currency, page, handleSearch]);

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      mode: "dark",
    },
  });

  const gridOptions = {
    rowHeight: 55,
    rowStyle: { fontFamily: "Montserrat", fontSize: 16, paddingTop: 6 },
  };

  const columns = [
    {
      headerName: "Coin",
      field: "name",
      cellRenderer: (params) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => history.push(`/coins/${params.data.id}`)}
          >
            <img
              src={params.data.image}
              alt={params.data.name}
              height="34"
              style={{ marginRight: 6 }}
            />
            <span
              style={{
                textTransform: "uppercase",
                fontSize: 16,
                marginRight: 6,
              }}
            >
              {params.data.symbol}
            </span>
            <span style={{ color: "darkgrey" }}>{params.data.name}</span>
          </div>
        );
      },
    },
    {
      headerName: "Price",
      field: "current_price",
      valueFormatter: (params) =>
        `${symbol} ${numberWithCommas(params.value.toFixed(2))}`,
      cellStyle: { color: "white" },
    },
    {
      headerName: "24h Change",
      field: "price_change_percentage_24h",
      valueFormatter: (params) =>
        `${params.value > 0 ? "+" : ""}${params.value.toFixed(2)}%`,
      cellStyle: (params) => ({
        color: params.value > 0 ? "rgb(14, 203, 129)" : "red",
        fontWeight: 500,
      }),
    },
    {
      headerName: "Market Cap",
      field: "market_cap",
      valueFormatter: (params) =>
        `${symbol} ${numberWithCommas(params.value.toString().slice(0, -6))}M`,
      cellStyle: { color: "white" },
    },
    {
      headerName: "Max Supply",
      field: "max_supply",
      valueFormatter: (params) =>
        params.value ? numberWithCommas(params.value) : "N/A",
      cellStyle: { color: "white" },
    },
    {
      headerName: "Total Volume",
      field: "total_volume",
      valueFormatter: (params) =>
        `${symbol} ${numberWithCommas(params.value.toFixed(2))}`,
      cellStyle: { color: "white" },
    },
    {
      headerName: "24h Low/High",
      field: "low_high",
      cellRenderer: (params) => {
        const { low_24h, high_24h } = params.data;
        return (
          <span style={{ color: "white" }}>
            <span style={{ color: "red" }}>{numberWithCommas(low_24h)}</span> /{" "}
            <span style={{ color: "green" }}>{numberWithCommas(high_24h)}</span>
          </span>
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <Container style={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          style={{ margin: 18, fontFamily: "Montserrat", alignItems: "center" }}
        >
          Cryptocurrency Prices by Market Cap
        </Typography>

        <TextField
          label="Search For a Crypto Currency.."
          variant="outlined"
          style={{
            fontSize: 22,
            fontWeight: 500,
            fontFamily: "Montserrat",
            borderRadius: 5,
            width: "100%",
            backgroundColor: "#E6007A",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Select
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currency}
                  style={{
                    width: 100,
                    height: 40,
                    color: "white",
                    backgroundColor: "#E6007A",
                    borderColor: "white",
                  }}
                  onChange={(e) => setCurrency(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#E6007A",
                        color: "white",
                      },
                    },
                  }}
                >
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"EUR"}>EUR</MenuItem>
                  <MenuItem value={"GBP"}>GBP</MenuItem>
                  <MenuItem value={"JPY"}>JPY</MenuItem>
                  <MenuItem value={"INR"}>INR</MenuItem>
                  <MenuItem value={"BTC"}>BTC</MenuItem>
                  <MenuItem value={"ETH"}>ETH</MenuItem>
                </Select>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div
          className="ag-theme-alpine-dark" // Use dark theme class
          style={{
            height: "600px",
            width: "100%",
            marginBottom: 20,
            backgroundColor: "#16171a",
          }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columns}
            rowData={rowData.slice((page - 1) * 10, (page - 1) * 10 + 10)}
            pagination={false}
            domLayout="autoHeight"
          />
        </div>

        <Pagination
          count={Math.ceil(totalCoins / 10)}
          style={{
            padding: 20,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          classes={{ ul: classes.pagination }}
          onChange={(_, value) => {
            setPage(value);
            window.scroll(0, 450);
          }}
        />
      </Container>
    </ThemeProvider>
  );
}
